<template>
  <header>
    <div id="header-sticky"
      :class="`${isSticky ? 'header__area header__transparent header__padding sticky'
      :'header__area header__transparent header__padding'} ${header__white && header__white}`"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
            <div class="header__left d-flex">
              <div class="logo">
                <router-link to="/">
                  <img v-if="header__white && !isSticky" 
                  src="../../assets/img/logo/transparant1.png" alt="logo" width="120"/>
                  
                  <img v-else src="../../assets/img/logo/transparant.png" alt="logo" width="120"/>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
            <div
              class="
                header__right
                d-flex
                justify-content-end
                align-items-center
              "
            >
              <div :class="`main-menu ${header__white && 'main-menu-3'}`">
                <nav id="mobile-menu" class="d-none d-xl-block">
                  <ul>
                    <li class="has-dropdown">
                      <router-link to="#">Formations</router-link>
                      <ul class="submenu">
                        <li><router-link to="#">PSP</router-link></li>
                        <li><router-link to="#">PSPO</router-link></li>
                        <li><router-link to="#">PSM</router-link></li>
                        <li><router-link to="#">ITIL 4</router-link></li>
                        <li><router-link to="#">Prince 2</router-link></li>
                        <li><router-link to="#">ISO</router-link></li>
                        <li><router-link to="#">CCNA</router-link></li>
                        <li><router-link to="#">Management 3.0</router-link></li>
                        <li><router-link to="#">Six Sigma</router-link></li>
                        <li><router-link to="#">ProKanban</router-link></li>
                        <li><router-link to="#">JIRA & Confluence</router-link></li>
                      </ul>
                    </li>
                    <li class="has-dropdown">
                      <router-link to="#">Services</router-link>
                      <ul class="submenu">
                        <li><router-link to="#">Consulting</router-link></li>
                        <li><router-link to="#">Rencontrer un coach</router-link></li>
                        <li>
                          <router-link to="#">Trouver un mentor</router-link>
                        </li>
                      </ul>
                    </li>
                    <li class="has-dropdown">
                      <router-link to="/blog">Examens blancs</router-link>
                      <ul class="submenu">
                        <li><router-link to="#">PMP</router-link></li>
                        <li><router-link to="#">CAPM</router-link></li>
                        <li><router-link to="#">PMI-ACP</router-link></li>
                        <li><router-link to="#">DASM</router-link></li>
                        <li><router-link to="#">PSM</router-link></li>
                        <li><router-link to="#">PSPO</router-link></li>
                      </ul>
                    </li>
                    <li><router-link to="#">Devenir formateur</router-link></li>
                  </ul>
                </nav>
              </div>
              <div class="header__btn ml-20 d-none d-sm-block">
                <router-link to="#" class="e-btn e-btn-border">Se connecter</router-link>
                <router-link to="#" class="e-btn e-btn-4 ml-10">Commencer</router-link>
              </div>
              <div class="sidebar__menu d-xl-none">
                <div @click="handleSidebar" class="sidebar-toggle-btn ml-30" id="sidebar-toggle">
                  <span class="line"></span>
                  <span class="line"></span>
                  <span class="line"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
<div @click="handleCartClose" :class="[showMiniCart ? 'body-overlay opened' : 'body-overlay']"></div>
<!-- cart mini area end -->

<!-- sidebar area start -->
  <div :class="`${showSidebar ? 'sidebar__area sidebar-opened' : 'sidebar__area'}`">
      <div class="sidebar__wrapper">
        <div @click="handleSidebarClose" class="sidebar__close">
            <button class="sidebar__close-btn" id="sidebar__close-btn">
            <span><i class="fal fa-times"></i></span>
            <span>close</span>
            </button>
        </div>
        <div class="sidebar__content">
            <div class="logo mb-40">
              <router-link to="/">
                <img src="../../assets/img/logo/logo.png" alt="logo">
              </router-link>
            </div>

             <div class="side-info-content sidebar-menu mm-menu">
                  
                <ul>
                  <li class="menu-item-has-children has-droupdown" 
                  v-bind:class="[menuOption.homeDropdown === true ? 'active' : '']">
                      <a @click="menuOption.homeDropdown = !menuOption.homeDropdown">Home</a >
                      <ul class="sub-menu" :class="[menuOption.homeDropdown === true ? 'active' : '',]">
                        <li>
                            <router-link to="#">Home Style 1</router-link>
                        </li>
                        <li>
                            <router-link to="#">Home Style 2</router-link>
                        </li>
                        <li>
                            <router-link to="#">Home Style 3</router-link>
                        </li>
                      </ul>
                  </li>


                    <li class="menu-item-has-children has-droupdown" 
                      :class="[menuOption.coursesDropdown === true ? 'active' : '',]">
                        <a @click="menuOption.coursesDropdown = !menuOption.coursesDropdown">Courses</a>
                        <ul class="sub-menu" :class="[menuOption.coursesDropdown === true ? 'active' : '',]">
                              <li>
                                <router-link to="#">Courses</router-link>
                              </li>
                              <li>
                                <router-link to="#">Course List</router-link>
                              </li>
                              <li>
                                <router-link to="#">Course sidebar
                                </router-link>
                              </li>
                              <li>
                                <router-link to="#">Course Details
                                </router-link>
                              </li>
                        </ul>
                      </li>

                  <li class="menu-item-has-children has-droupdown"
                    :class="[menuOption.blogDropdown === true ? 'active' : '']">

                    <a v-on:click="menuOption.blogDropdown = !menuOption.blogDropdown">Blog</a>
                    <ul class="sub-menu"
                      :class="[menuOption.blogDropdown === true ? 'active' : '',]">
                      <li>
                          <router-link to="#">Blog</router-link>
                      </li>
                      <li>
                          <router-link to="#">Blog Details</router-link>
                      </li>
                    </ul>
                  </li>

                      
                  <li class="menu-item-has-children has-droupdown" 
                  :class="[menuOption.pagesDropDown === true ? 'active' : '',]">
                      <a @click="menuOption.pagesDropDown = !menuOption.pagesDropDown">Pages</a>
                      <ul class="sub-menu" 
                      :class="[menuOption.pagesDropDown === true ? 'active' : '',]">
                            <li><router-link to="#">About</router-link></li>
                            <li><router-link to="#">Instructor</router-link></li>
                            <li>
                            <router-link to="#">
                            Instructor Details</router-link>
                            </li>
                            <li><router-link to="#">Event Details</router-link></li>
                            <li><router-link to="#">My Cart</router-link></li>
                            <li><router-link to="#">Checkout</router-link></li>
                            <li><router-link to="#">Sign In</router-link></li>
                            <li><router-link to="#">Sign Up</router-link></li>
                            <li><router-link to="#">Error</router-link></li>
                      </ul>
                  </li>

                  <li>
                      <router-link to="#" class="border-0">Contact</router-link>
                  </li>
                  
              </ul>
            </div>

            <div class="sidebar__search p-relative mt-40 ">
              <form action="#">
                  <input type="text" placeholder="Search...">
                  <button type="submit"><i class="fad fa-search"></i></button>
              </form>
            </div>
            <div class="sidebar__cart mt-30">
              <a href="#">
                  <div class="header__cart-icon">
                    <svg viewBox="0 0 24 24">
                        <circle class="st0" cx="9" cy="21" r="1"/>
                        <circle class="st0" cx="20" cy="21" r="1"/>
                        <path class="st0" d="M1,1h4l2.7,13.4c0.2,1,1,1.6,2,1.6h9.7c1,0,1.8-0.7,2-1.6L23,6H6"/>
                    </svg>
                  </div>
                  <span class="cart-item">2</span>
              </a>
            </div>
        </div>
      </div>
  </div> 
  <div @click="handleSidebarClose" :class="[showSidebar ? 'body-overlay opened' : 'body-overlay']"></div>
  <!-- sidebar area end -->


</template>

<script>
export default {
  name: "HomeHeader",
  props:{
    header__white:String,
  },
  data () {
    return {
      isSticky:false,
      showMiniCart:false,
      showSidebar:false,
      value:1,
      valueTwo:1,
      valueThree:1,
      menuOption: {
        menuShow: false,
        menuSearch: false,
        homeDropdown: false,
        coursesDropdown: false,
        pagesDropDown: false,
        blogDropdown: false,
        aboutDropdown: false,
      },
    }
  },
  methods:{
      handleSticky () {
          if(window.scrollY > 80){
             this.isSticky = true;
          }
          else{
             this.isSticky = false;
          }
       },
       handleMiniCart () {
         this.showMiniCart = true;
       },
       handleCartClose () {
         this.showMiniCart = false;
       },
       handleSidebar () {
         this.showSidebar = true;
       },
       handleSidebarClose () {
         this.showSidebar = false;
       },
       handleIncreaseValue (value) {
         if(value === 'valueOne'){
           this.value++
         }
         if(value === 'valueTwo'){
           this.valueTwo++
         }
         if(value === 'valueThree'){
           this.valueThree++
         }
         
       },
       handleDecreaseValue (value) {
         if(value === 'valueOne' && this.value > 0){
           this.value--
         }
         if(value === 'valueTwo' && this.valueTwo > 0){
           this.valueTwo--
         }
         if(value === 'valueThree' && this.valueThree > 0){
           this.valueThree--
         }
       }
  },
  mounted() {
      window.addEventListener('scroll',this.handleSticky)
   },
};
</script>