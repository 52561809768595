<template>
 <footer>
   <div :class="`footer__area ${footerBgTwo ? 'grey-bg-2' : 'footer-bg'}`">
      <div :class="`footer__top ${footerPadding ? 'pt-90' : 'pt-190'} pb-40`">
         <div class="container">
            <div class="row">
               <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <div class="footer__logo">
                           <router-link to="/">
                              <img :src="`${footerBgTwo ? require('../../assets/img/logo/transparant.png') : require('../../assets/img/logo/transparant1.png')}`" alt="" width="120">
                           </router-link>
                        </div>
                     </div>
                     <div :class="`${footerBgTwo ? 'footer__widget-body-2' : 'footer__widget-body'}`">
                        <p>Great lesson ideas and lesson plans for ESL teachers! Educators can customize lesson plans to best.</p>

                        <div class="footer__social">
                           <ul>
                              <li><a href="https://www.linkedin.com/company/atypic-digital/"><i class="fab fa-linkedin"></i></a></li>
                              <li><a href="https://twitter.com/atypic_digital" class="tw"><i class="fab fa-twitter"></i></a></li>
                              <li><a href="https://www.instagram.com/atypic_digital/" ><i class="fab fa-instagram"></i></a></li>

                              <li><a href="https://www.tiktok.com/@atypic_digital"><i class="fab fa-tiktok"></i></a></li>
                              <li><a href="https://www.youtube.com/@agileafricatv" class="pin"><i class="fab fa-youtube"></i></a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-2 offset-xxl-1 col-xl-2 offset-xl-1 col-lg-3 offset-lg-0 col-md-2 offset-md-1 col-sm-5 offset-sm-1">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Entreprise</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                           <ul>
                              <li><a href="#">Formations sur mesure</a></li>
                              <li><a href="#">Team building</a></li>
                              <li><a href="#">Accompagnement post-formation</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Gouvernement</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                           <ul>
                              <li><a href="#">Gestion des programmes</a></li>
                              <li><a href="#">Stratégie de mise en oeuvre</a></li>
                              <li><a href="#">Suivi & évaluation (de maturité digitale)</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">A propos</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                           <ul>
                              <li><a href="#">Contactez-nous</a></li>
                              <li><a href="#">A propos</a></li>
                              <li><a href="#">Blog</a></li>
                              <li><a href="#">Podcast (Spotify & Youtube)</a></li>
                              <li><a href="#">Infographie</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Contact</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                           <ul>
                              <li> <a href="mailto:contact@atypix.com">Contactez-moi !</a></li>
                              <li>+221 77 000 00 00</li>
                              <li>Montréal |</li>
                              <li>Dakar |</li>
                              <li>Contonou |</li>
                              <li>Abidjan |</li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <!-- <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6">
                  <div class="footer__widget footer__pl-70 mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Subscribe</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__subscribe-2' : 'footer__subscribe'}`">
                           <form action="#">
                              <div class="footer__subscribe-input mb-15">
                                 <input type="email" placeholder="Your email address">
                                 <button type="submit">
                                    <i class="far fa-arrow-right"></i>
                                    <i class="far fa-arrow-right"></i>
                                 </button>
                              </div>
                           </form>
                           <p>Get the latest news and updates right at your inbox.</p>
                        </div>
                     </div>
                  </div>
               </div> -->
            </div>
         </div>
      </div>
      <div :class="`${footerBgTwo ? 'footer__bottom footer__bottom-2' : 'footer__bottom'}`">
         <div class="container">
            <div class="row">
               <div class="col-xxl-12">
                  <div :class="`${footerBgTwo ? 'footer__copyright-2 text-center' : 'footer__copyright text-center'}`">
                     <p>© 2024 <a target="_blank" href="https://atypicdigital.com/fr/">Atypix</a>, All Rights Reserved. Design By <a target="_blank" href="http://goodev.co">Goodev</a></p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</footer>
</template>

<script>
export default {
   name:'FooterArea',
   props:{
      footerBgTwo:String,
      footerPadding:String,
   }
};
</script>
