<template>
<section class="hero__area hero__height d-flex align-items-center grey-bg-2 p-relative">
   <div class="hero__shape">
      <img class="hero-1-circle" src="../../assets/img/shape/hero/hero-1-circle.png" alt="">
      <img class="hero-1-circle-2" src="../../assets/img/shape/hero/hero-1-circle-2.png" alt="">
      <img class="hero-1-dot-2" src="../../assets/img/shape/hero/hero-1-dot-2.png" alt="">
   </div>
   <div class="container">
      <div class="hero__content-wrapper">
         <div class="row align-items-center">
            <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
               <div class="hero__content p-relative z-index-1">
                  <h3 class="hero__title">
                     <span></span>
                     <span class="yellow-shape">Atypix.com <img src="../../assets/img/shape/yellow-bg.png" alt="yellow-shape"> </span> 
                     Pour apprendre et progresser ensemble.</h3>
                     <p>Développez vos compétences numériques avec nos formations en gestion de projet, 
                        design de produit, les méthodes agile, cybersécurité, IA et bien plus encore.</p>
                        <div>
                           <div class="course__payment mb-35">
               <h3>Nos certifications </h3>
               <a href="#">
                  <img src="../../assets/img/hero/psm.png" alt="logo_psm" width="60">
               </a>
               <a href="#" class="ml-10">
                  <img src="../../assets/img/hero/pmp.png" alt="logo_psm" width="60">
               </a>
               <a href="#" class="ml-10">
                  <img src="../../assets/img/hero/psm.png" alt="logo_psm" width="60">
               </a>
               <a href="#" class="ml-10">
                  <img src="../../assets/img/hero/pmp.png" alt="logo_psm" width="60">
               </a>
               <a href="#" class="ml-10">
                  <img src="../../assets/img/hero/psm.png" alt="logo_psm" width="60">
               </a>
            </div>
                        </div>
                        <router-link to="#" class="e-btn e-btn-4 mr-10">Commencer</router-link>
                        <router-link to="#" class="e-btn e-btn-border">En savoir plus</router-link>
               </div>
            </div>
            <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5">
               <div class="hero__thumb d-flex p-relative">
                  <div class="hero__thumb-shape">
                     <img class="hero-1-dot" src="../../assets/img/shape/hero/hero-1-dot.png" alt="">
                     <img class="hero-1-circle-3" src="../../assets/img/shape/hero/hero-1-circle-3.png" alt="">
                     <img class="hero-1-circle-4" src="../../assets/img/shape/hero/hero-1-circle-4.png" alt="">
                  </div>
                  <div class="hero__thumb-big ml-70">
                     <img src="../../assets/img/hero/hero-1.jpg" alt="">
                     <div class="hero__quote hero__quote-animation">
                        <span>20K sont déja formés</span>
                        <h4>“When I Grow Up” Spirit Day!</h4>
                     </div>
                  </div>
                  <!-- <div class="hero__thumb-sm mt-50 d-none d-lg-block">
                     <img src="../../assets/img/hero/hero-sm-1.jpg" alt="">
                  </div> -->
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
export default {
  name: "HeroSection",
};
</script>