 <template>
    <Header/>
    <HeroSection/>
    <PartnerArea/>
    <Course/>
    <CounterArea/>
    <CategoryArea/>
    <TeachersArea/>
    <TestimonialArea/>
    <Events/>
    <BannerArea/>
    <!-- <Pricing/> -->
    <Footer footerPadding="true"/>
 </template>

 <script>
import Header from '../components/Home/Header.vue';
import HeroSection from '../components/Home/HeroSection.vue';
import PartnerArea from '../components/Home/Partner.vue';
import CounterArea from '../components/Home/Counter.vue';
import CategoryArea from '../components/Home/CategoryArea.vue';
import TeachersArea from '../components/Home/Teachers.vue'
import TestimonialArea from '../components/Home/Testimonial.vue'
import BannerArea from '../components/Home/BannerArea.vue';
import Course from '../components/Home/Course.vue';
import Events from '../components/Home/Events.vue';
// import Pricing from '../components/Home/Pricing.vue';
import Footer from '../components/Home/Footer.vue';

 export default {
     name:'HomePage',
     components:{
         Header,
         HeroSection,
         PartnerArea,
         CounterArea,
         CategoryArea,
         TeachersArea,
         TestimonialArea,
         BannerArea,
         Course,
         Events,
        //  Pricing,
         Footer,
     }
 }
 </script>