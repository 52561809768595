<template>
  <section class="banner__area pb-110">
    <div class="container">
      <div class="justify-content-center row">
        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
          <div class="banner__item p-relative mb-40" :style="{background:'url('+ require('../../assets/img/banner/atypix_banner_blue.png')+')',backgroundRepeat:'no-repeat',backgroundSize:'cover'}">
            <div class="justify-content-center row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                <div class="banner__thumbd-none d-sm-block d-md-none d-lg-block">
                  <img src="../../assets/img/logo/transparant1.png" alt=""  width="200"/>
                </div>
              </div>
              <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                <div class="banner__content">
                  <div class="banner_title mb-45">
                      <h2 class="banner_title" style="color: white;"> Venez nous rejoindre</h2>
                      <p style="color: white;">You don't have to struggle alone, you've got our </p>
                      <router-link to="#" class="e-btn e-btn-2">Joindre la communauté</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BannerArea",
};
</script>
